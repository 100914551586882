<template>
  <!-- <div class="prices-container"> -->
  <div class="stockPrivate">
    <h1 class="has-text-primary has-text-weight-bold" :class="displayOnTouch ? 'is-size-huge-mobile' : 'is-size-huge'">
    Stockage<br>
    entre<br>
    particuliers
    </h1>
    <div class="columns is-multiline">
      <div class="column is-12">
        <h2 class="has-text-primary is-size-3 has-text-weight-bold is-uppercase">faire gardienner son bateau: comment faire?</h2>
      </div>
      <div class="column is-9 with-before has-margin-bottom-5">
        <p>L’été n’a pas encore commencé, que se profile déjà la question tant redoutée : où va-t-on stocker son bateau cet hiver ? Véritable casse-tête pour les bateaux-skippers (propriétaires de bateaux), le stockage ne devrait pas être si compliqué. Or les solutions traditionnelles de garde-bateaux existantes ne répondent plus aux besoins des propriétaires, toujours plus nombreux (places trop onéreuses, mal localisées ou parfois tout simplement introuvables). Face à ce constat et à l’heure où les nouvelles technologies peuvent favoriser l’économie locale et les échanges entre les personnes, JeStockeMonBateau.fr constitue une solution de stockage sur mesure : le stockage entre particuliers (on parle aussi « costockage ») !
        </p>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <h2 class="has-text-primary is-size-3 has-text-weight-bold is-uppercase">
          <span class="break">JeStocke<br class="thisBreak">MonBateau.fr:</span><br/>
          LA solution qui révolutionne le stockage de bateaux !
        </h2>
      </div>
      <div class="column is-9 stockBox">
        <p>Partie intégrante de l’économie collaborative, la nouvelle tendance est donc au stockage entre particuliers. Et le moins que l’on puisse dire, c’est que ce concept fait des adeptes ! Le principe est simple : un particulier loue un ou des espaces dont il n’a pas l’utilité à un autre particulier, au sein d’une propriété privée.</p>
        <p>
          <strong class="is-uppercase">Un système gagnant – gagnant</strong>
          <br>
          Dans un contexte économique, où chacun cherche à optimiser ses finances, le stockage collaboratif est l’avenir du stockage des bateaux.<br>
          Vous êtes propriétaire d’un bateau de plaisance et êtes à la recherche d’une solution simple, pas chère et sécurisée pour stocker votre bateau ?<br>
          Vous disposez d’un espace inutilisé (quelques m² suffisent) et souhaitez gagner de l’argent en le mettant en location ? Entrez en relation !
        </p>
        <p>
          <strong class="is-uppercase">Une solution personnalisée</strong><br>
          L’humain est placé au cœur des échanges. L’objectif est de réduire au maximum les intermédiaires et d’offrir un service sur-mesure. Participatif, JeStockeMonBateau.fr est aussi l’occasion de faire des rencontres et de partager des moments conviviaux.
          Le bateau-skipper, en plus d’importantes économies financières, bénéficie également d’un accès facilité à son bien tout au long de l’année. Le bateau-stockeur, quant à lui, perçoit un revenu en rentabilisant son espace, jusque-là inexploité.
        </p>
        <p>
          <strong class="is-uppercase">Un stockage entièrement sécurisé</strong><br>
          Le maitre mot du stockage entre particuliers est la sécurité.
          Nous vous mettons à disposition des milliers de m² (distribués partout en France) et vous accompagnons tout au long de vos démarches !
        </p>
      </div>
      <div class="column is-3 is-hidden-mobile">
        <div class="">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StorageRules',
  data () {
    return {
    }
  },
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Rules' });

    let title = (siteName+' - Règles - Stockage de bateaux chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
      description = siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! La première plateforme dans le monde mettant en relation des propriétaires d\'un jardin, d\'un abri, d\'un box ou d\'un garage, avec des plaisanciers qui recherchent un endroit où stocker leur bateau.';

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'property': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'property': 'twitter:image',
          'content': (baseUrl+require('./../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .stockPrivate {
    margin: 5% 30%;
  }
  @media screen and (max-width: 2048px){
    .stockPrivate {
      margin: 5% 25%;
    }
  }
  @media screen and (max-width: 1640px){
    .stockPrivate {
      margin: 5% 20%;
    }
  }
  @media screen and (max-width: 1365px){
    .stockPrivate {
      margin: 5% 15%;
    }
  }
  @media screen and (max-width: 1171px){
    .stockPrivate {
      margin: 5% 10%;
    }
  }

  .prices-container h1{
    padding-bottom: 5%;
  }
  .is-size-huge {
    font-size: 10rem;
    line-height: 8rem;
    margin: 4rem 0 6rem;
    hyphens: auto;
  }
  .is-size-huge-mobile{
    font-size: 6rem;
    line-height: 4.2rem;
  }
  h2 {
    line-height: 2rem;
    margin-top: 2rem;
    margin-bottom: 6rem;
    position: relative;
    z-index: 12;
    word-break: break-word;
  }
  p {
    margin-bottom: 1rem;
    text-align: justify;
  }
  .with-before {
    position: relative;
    z-index: 11;
  }
  .has-margin-bottom-5 {
    margin-bottom: 5rem;
  }
  .with-before:before {
    content: "";
    display: block;
    height: 0;
    padding-top: 30%;
    width: 40%;
    top:-40%;
    left:-10%;
    z-index: 9;
    background: #f2f5fb;
    position: absolute;
  }
  .contentStockbox {
    position: relative;
  }
  .stockBox {
    position: relative;
    z-index: 2;
    overflow: hidden;
  }
  .stockBox:after {
    content: "";
    display: block;
    height: 0;
    padding-top: 45%;
    width: 60%;
    top: -10%;
    right: -50%;
    z-index: 9;
    background: #f2f5fb;
    position: absolute;
  }
  .break {
    word-break: break-all
  }
  .thisBreak {
      display: none;
    }
  .stockBox p,
  .with-before p {
    position: relative;
    z-index: 40;
  }
  @media screen and (max-width: 1087px) {
    .container {
      margin: 5%;
    }
  }
  @media screen and (max-width: 537px) {
    .container {
      margin: 5%;
    }
    .is-size-huge-mobile{
      font-size: 3rem;
      line-height: 3rem;
    }
    .thisBreak {
      display: block;
    }
  }
</style>

